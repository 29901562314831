import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthGuard } from '../components/authentication/auth-guard';
import ACL from '../components/authentication/ACL';
import LoadingScreen from '../components/LoadingScreen';
import { DashboardLayout } from '../components/dashboard/dashboard-layout';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// // Dashboard pages

const General = Loadable(lazy(() => import('../pages/dashboard/')));
const Station = Loadable(lazy(() => import('../pages/dashboard/station/')));
const StationList = Loadable(lazy(() => import('../pages/dashboard/station/list')));
const StationEditOrCreate = Loadable(lazy(() => import('../pages/dashboard/station/edit')));
const Maps = Loadable(lazy(() => import('../pages/dashboard/map')));
const Planner = Loadable(lazy(() => import('../pages/dashboard/planner')));
const Logistics = Loadable(lazy(() => import('../pages/dashboard/logistics')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/calendar')));
// const CustomerDetails = Loadable(lazy(() => import('./pages/dashboard/CustomerDetails')));
// const CustomerEdit = Loadable(lazy(() => import('./pages/dashboard/CustomerEdit')));
const VeList = Loadable(lazy(() => import('../pages/dashboard/ve/list')));
const VeIndex = Loadable(lazy(() => import('../pages/dashboard/ve/index')));
const VeEdit = Loadable(lazy(() => import('../pages/dashboard/ve/edit')));

const SiteList = Loadable(lazy(() => import('../pages/dashboard/geosite/list')));
const SiteIndex = Loadable(lazy(() => import('../pages/dashboard/geosite/index')));
const SiteEditOrCreate = Loadable(lazy(() => import('../pages/dashboard/geosite/edit')));

// const Finance = Loadable(lazy(() => import('./pages/dashboard/Finance')));
// const InvoiceDetails = Loadable(lazy(() => import('./pages/dashboard/InvoiceDetails')));
// const InvoiceList = Loadable(lazy(() => import('./pages/dashboard/InvoiceList')));
// const Kanban = Loadable(lazy(() => import('./pages/dashboard/Kanban')));
// const Mail = Loadable(lazy(() => import('./pages/dashboard/Mail')));
// const OrderDetails = Loadable(lazy(() => import('./pages/dashboard/OrderDetails')));
// const OrderList = Loadable(lazy(() => import('./pages/dashboard/OrderList')));
const User = Loadable(lazy(() => import('../pages/dashboard/user/')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/user/list')));
const UserEdit = Loadable(lazy(() => import('../pages/dashboard/user/edit')));

const Incident = Loadable(lazy(() => import('../pages/dashboard/incident/')));
const IncidentList = Loadable(lazy(() => import('../pages/dashboard/incident/list')));
const IncidentEditOrCreate = Loadable(lazy(() => import('../pages/dashboard/incident/edit')));

const UserFeedback = Loadable(lazy(() => import('../pages/dashboard/UserFeedback/')));
const UserFeedbackList = Loadable(lazy(() => import('../pages/dashboard/UserFeedback/list')));

// // Error pages
const AuthorizationRequired = Loadable(lazy(() => import('../pages/401')));
const NotFound = Loadable(lazy(() => import('../pages/404')));
const ServerError = Loadable(lazy(() => import('../pages/500')));


const routes = [
  {
    path: '',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <General />
      },
      {
        path: 've',
        children: [
          {
            path: '',
            element: <VeList />
          },
          // {
          //   path: ':stationId',
          //   element: <Station />
          // },
          // {
          //   path: ':stationId/edit',
          //   element: <StationEditOrCreate />,
          // }
        ]
      },
      {
        path: 'station',
        children: [
          {
            path: '',
            element: <StationList />
          },
          {
            path: 'create',
            element: <StationEditOrCreate />
          },
          {
            path: ':stationId',
            element: <Station />
          },
          {
            path: ':stationId/edit',
            element: <StationEditOrCreate />,
          },
          // {
          //   path: ':stationId/session/',
          //   element: <Maps />,
          // },
          // {
          //   path: ':stationId/session/:sessionId',
          //   element: <Maps />,
          // }
        ]
      },
      {
        path: 'geosite',
        children: [
          {
            path: '',
            element: <SiteList />
          },
          {
            path: 'create',
            element: <SiteEditOrCreate />
          },
          {
            path: ':id',
            element: <SiteIndex />
          },
          {
            path: ':id/edit',
            element: <SiteEditOrCreate />,
          },
        ]
      },
      {
        path: 've',
        children: [
          {
            path: '',
            element: <VeList />
          },
          // {
          //   path: ':stationId',
          //   element: <Station />
          // },
          // {
          //   path: ':stationId/edit',
          //   element: <StationEditOrCreate />,
          // }
        ]
      },
      {
        path: 'incident',
        children: [
          {
            path: '',
            element: <IncidentList />
          },
          {
            path: 'create',
            element: <IncidentEditOrCreate />
          },
          {
            path: ':incidentId',
            element: <Incident />
          },
          {
            path: ':incidentId/edit',
            element: <IncidentEditOrCreate />,
          },
        ]
      },
      {
        path: 'user_feedback',
        children: [
          {
            path: '',
            element: <UserFeedbackList />
          },
          {
            path: ':incidentId',
            element: <UserFeedback />
          }
        ]
      },
      {
        path: 'map',
        element: (
          <Maps />
        )
      },
      {
        path: 'planner',
        element: (
          <Planner />
        )
      },
      {
        path: 'calendar',
        element: (
          <Calendar />
        )
      },
      {
        path: 'logistics',
        element: (
          <ACL allowedRoles={['8GE_ADMIN']} fallback={<AuthorizationRequired />}>
            <Logistics />
          </ACL>
        )
      },
      {
        path: 'user',
        children: [
          {
            path: '',
            element: (
              <ACL allowedRoles={['8GE_ADMIN']} fallback={<AuthorizationRequired />}>
                <UserList />
              </ACL>
            )
          },
          {
            path: ':userId',
            element: (
              <ACL allowedRoles={['8GE_ADMIN']} fallback={<AuthorizationRequired />}>
                <User />
              </ACL>
            )
          },
          {
            path: ':userId/edit',
            element: (
              <ACL allowedRoles={['8GE_ADMIN']} fallback={<AuthorizationRequired />}>
                <UserEdit />
              </ACL>
            ),
          }
        ]
      },

      // {
      //   path: 'mail',
      //   children: [
      //     {
      //       path: '/',
      //       element: (
      //         <Navigate
      //           to="/dashboard/mail/all"
      //           replace
      //         />
      //       )
      //     },
      //     {
      //       path: 'label/:customLabel',
      //       element: <Mail />
      //     },
      //     {
      //       path: 'label/:customLabel/:emailId',
      //       element: <Mail />
      //     },
      //     {
      //       path: ':systemLabel',
      //       element: <Mail />
      //     },
      //     {
      //       path: ':systemLabel/:emailId',
      //       element: <Mail />
      //     }
      //   ]
      // },
    ]
  },
  {
    path: '401',
    element: <AuthorizationRequired />
  },
  {
    path: '404',
    element: <NotFound />
  },
  {
    path: '500',
    element: <ServerError />
  },
  {
    path: '*',
    element: <NotFound />
  }
];

export default routes;
