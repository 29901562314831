import { useEffect, useMemo, useRef, useState } from 'react';
import { matchRoutes, useLocation, Link as RouterLink } from "react-router-dom"
import PropTypes from 'prop-types';
import { Box, Button, Chip, Divider, Drawer, Typography, useMediaQuery } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import ElectricCarOutlinedIcon from '@mui/icons-material/ElectricCarOutlined';
import EvStationOutlinedIcon from '@mui/icons-material/EvStationOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined';
import CrisisAlertOutlinedIcon from '@mui/icons-material/CrisisAlertOutlined';
import FeedbackIcon from '@mui/icons-material/Feedback';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import CarRentalIcon from '@mui/icons-material/CarRental';
import { Logo } from '../logo';
import { Scrollbar } from '../scrollbar';
import { DashboardSidebarSection } from './dashboard-sidebar-section';
import { OrganizationPopover } from './organization-popover';
import { useAuth } from '../../hooks/use-auth';

const sections = [
  {
    title: 'Général',
    allowedRoles: ['8GE_ADMIN', 'UTILISATEUR_ENEDIS'],
    items: [
      {
        title: 'Accueil',
        allowedRoles: ['8GE_ADMIN', 'UTILISATEUR_ENEDIS'],
        path: '/',
        icon: <HomeOutlinedIcon fontSize="small" />
      },
      {
        title: 'Itinéraire',
        allowedRoles: ['8GE_ADMIN'],
        path: '/planner',
        icon: <RouteOutlinedIcon fontSize="small" />
      },
      {
        title: 'Carte des bornes',
        allowedRoles: ['8GE_ADMIN', 'UTILISATEUR_ENEDIS'],
        path: '/map',
        icon: <MapOutlinedIcon fontSize="small" />
      },
      {
        title: 'Bornes de recharge',
        allowedRoles: ['8GE_ADMIN', 'UTILISATEUR_ENEDIS'],
        path: '/station',
        icon: <EvStationOutlinedIcon fontSize="small" />
      },
      {
        title: 'Sites Enedis',
        allowedRoles: ['8GE_ADMIN', 'UTILISATEUR_ENEDIS'],
        path: '/geosite',
        icon: <HomeWorkIcon fontSize="small" />
      },
      {
        title: 'Véhicules électriques',
        allowedRoles: ['8GE_ADMIN'],
        path: '/ve',
        icon: <ElectricCarOutlinedIcon fontSize="small" />
      },
      {
        title: 'Réservation voiture',
        allowedRoles: ['8GE_ADMIN'],
        path: '/calendar',
        icon: <CarRentalIcon fontSize="small" />
      }
    ]
  },
  {
    title: 'Management',
    allowedRoles: ['8GE_ADMIN'],
    items: [
      {
        title: 'Logistique',
        allowedRoles: ['8GE_ADMIN'],
        path: '/logistics',
        icon: <WorkspacesOutlinedIcon fontSize="small" />
      },
      {
        title: 'Remontées terrain',
        allowedRoles: ['8GE_ADMIN'],
        path: '/user_feedback',
        icon: <FeedbackIcon fontSize="small" />
      },
      {
        title: 'Gestion des incidents',
        allowedRoles: ['8GE_ADMIN'],
        path: '/incident',
        icon: <CrisisAlertOutlinedIcon fontSize="small" />
      },
      {
        title: 'Utilisateurs',
        allowedRoles: ['63G_ADMIN'],
        path: '/user',
        icon: <GroupOutlinedIcon fontSize="small" />,
      },
      {
        title: 'Périmètre',
        allowedRoles: ['63G_ADMIN'],
        path: '/workspace',
        icon: <WorkspacesOutlinedIcon fontSize="small" />
      },
    ]
  },
];

export const DashboardSidebar = (props) => {
  const { onClose, open } = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
    noSsr: true
  });
  const { user } = useAuth();
  const location = useLocation()

  const organizationsRef = useRef(null);
  const [openOrganizationsPopover, setOpenOrganizationsPopover] = useState(false);


  const handleOpenOrganizationsPopover = () => {
    setOpenOrganizationsPopover(true);
  };

  const handleCloseOrganizationsPopover = () => {
    setOpenOrganizationsPopover(false);
  };

  const handlePathChange = () => {
    if (open) {
      onClose?.();
    }
  };

  useEffect(handlePathChange,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname]);

  const content = (
    <>
      <Scrollbar
        sx={{
          height: '100%',
          '& .simplebar-content': {
            height: '100%'
          }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <div>
          <Box sx={{ p: 3, display: 'flex',  alignItems: 'center' }}>
              <RouterLink to={'/'}>
                <Logo
                  sx={{
                    height: 42,
                    width: 42
                  }}
                />
              
              </RouterLink>
              <Typography
              sx={{ ml: 2}}
              color="neutral.100"
              variant="h5"
                  >
                CarVE
                  </Typography>
            </Box>
            <Box sx={{ px: 2 }}>
              <Box
                onClick={handleOpenOrganizationsPopover}
                ref={organizationsRef}
                sx={{
                  alignItems: 'center',
                  backgroundColor: 'rgba(255, 255, 255, 0.04)',
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'space-between',
                  px: 3,
                  py: '11px',
                  borderRadius: 1
                }}
              >
                <div>
                  <Typography
                    color="inherit"
                    variant="subtitle1"
                  >
                    {user.cn}
                  </Typography>
                  <Typography
                    color="neutral.400"
                    variant="body2"
                  >
                    {'Direction Centre Val de Loire'}
                  </Typography>
                </div>
                <UnfoldMoreIcon
                  sx={{
                    color: 'neutral.500',
                    width: 20,
                    height: 20
                  }}
                />
              </Box>
            </Box>
          </div>
          <Divider
            sx={{
              borderColor: '#2D3748',
              my: 3
            }}
          />
          <Box sx={{ flexGrow: 1 }}>
            {sections.map((section) => (
              <DashboardSidebarSection
                key={section.title}
                path={location.pathname}
                sx={{
                  mt: 2,
                  '& + &': {
                    mt: 2
                  }
                }}
                {...section} />
            ))}
          </Box>
          <Divider
            sx={{
              borderColor: '#2D3748'  // dark divider
            }}
          />
          <Box sx={{ p: 2 }}>
            <Typography
              color="neutral.100"
              variant="subtitle2"
            >
              {"Besoin d'aide ?"}
            </Typography>
            <Typography
              color="neutral.500"
              variant="body2"
            >
              {'Contactez nos équipes'}
            </Typography>
            <Button
              color="secondary"
              component="a"
              fullWidth
              sx={{ mt: 2 }}
              variant="contained"
            >
              {'Contact'}
            </Button>
          </Box>
        </Box>
      </Scrollbar>
      <OrganizationPopover
        anchorEl={organizationsRef.current}
        onClose={handleCloseOrganizationsPopover}
        open={openOrganizationsPopover}
      />
    </>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'neutral.900',
            borderRightColor: 'divider',
            borderRightStyle: 'solid',
            borderRightWidth: (theme) => theme.palette.mode === 'dark' ? 1 : 0,
            color: '#FFFFFF',
            width: 280
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'neutral.900',
          color: '#FFFFFF',
          width: 280
        }
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};
